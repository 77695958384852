@tailwind base;
@tailwind components;
@tailwind utilities;

/* default styles */

body {
  color: #3f3837;
}

strong {
  font-weight: 'medium';
}

.icon-fill-red {
  fill: #da291c;
}

.icon-fill-white {
  fill: white;
}

/* carousel */

/* purgecss start ignore */
@media (min-width: 768px) {
  .video-full {
    width: 588px;
    height: 336px;
  }
}

select::-ms-expand {
  display: none;
}

.transform-align-center {
  transform: translate(-50%, -50%);
}

.carousel .slider-wrapper,
.carousel .carousel,
.carousel .slider {
  height: 100%;
}

.header-carousel .control-dots {
  margin-top: -40% !important;
  bottom: auto !important;
}

.carousel .dot {
  background: white !important;
  opacity: 1 !important;
  box-shadow: none !important;
}

.carousel .dot.selected {
  background: red !important;
}

.carousel .legend {
  background-color: white !important;
  color: black !important;
}

.project-carousel .carousel-slider {
  height: 100%;
}

.project-carousel .carousel .slide {
  background: transparent;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgb(255 255 255 / 0%) 40%,
    rgb(0 0 0 / 70%) 100%
  );
  mix-blend-mode: darken;
}

/* purgecss end ignore */

/* Swiper - override slide styles */

/* purgecss start ignore */
.competences-projects-swiper .swiper-slide {
  height: auto !important;
  width: calc(100% - 60px);
}

.swiper-container {
  --swiper-navigation-size: 14px;
}
@screen sm {
  .competences-projects-swiper .swiper-slide {
    width: 310px;
  }

  .swiper-container {
    --swiper-navigation-size: 24px;
  }
}

.competences-projects-swiper .swiper-button-next,
.competences-projects-swiper .swiper-button-prev {
  height: 100% !important;
  top: 0 !important;
  margin-top: 0 !important;
  color: white !important;
  width: 30px !important;
  transition: all 0.3s;
}
@screen sm {
  .competences-projects-swiper .swiper-button-next,
  .competences-projects-swiper .swiper-button-prev {
    width: 50px !important;
  }
}

.competences-projects-swiper .swiper-button-next {
  right: 0 !important;
  background: rgb(0 0 0 / 20%);
}

.competences-projects-swiper .swiper-button-prev {
  left: 0 !important;
  background: rgb(0 0 0 / 20%);
}

.competences-projects-swiper .swiper-button-disabled {
  opacity: 0 !important;
}

/* purgecss end ignore */

/* tbf logo */

@media only screen and (max-width: 550px) {
  .crop-on-mobile {
    object-fit: cover;
    height: 44px;
    width: 48px;
    object-position: left;
  }
}

/* the famous hover */

.tbf-hover {
  outline: transparent 18px solid;
  transition: all 0.5s ease-out;
}

@media only screen and (min-width: 550px) {
  .tbf-hover:hover {
    outline: #fff166 18px solid;
    transition: all 0.2s ease-in;
  }
}

/* masonry (stories) */

.stories-masonry-column {
  background-clip: padding-box;
}

/* VIMEO RESPONSIVE BACKGROUND
   From: https://gist.github.com/BarryMode/7594871a43775dc70f92af5ad45a2774
*/

.vimeo-background-container {
  padding: 0;
  height: 100%;
}

.vimeo-background-container iframe {
  box-sizing: border-box;
  width: 85vh;
  height: 56.25vw;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (orientation: portrait), screen and (max-width: 1023px) {
  .vimeo-background-container iframe {
    width: 177.7778vh;
  }
}

/* ADVISOR DETAIL PAGE */

.advisor-description p {
  margin-bottom: 10px;
}

.advisor-description ul {
  margin-bottom: 10px;
  padding-left: 30px;
}

.advisor-description li {
  list-style-type: disc;
}
